.create-role{
    font-family: 'Open Sans';
    font-size: 13px;
    width: 75rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    height: 3rem;

}

.create-reason{
    font-family: 'Open Sans';
    font-size: 13px;
    width: 75rem;
    border: 1px solid #000000;
    border-radius: 3px;
    outline: none;
    height: 3rem;

}

.checkbox-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.checkbox-table th, .checkbox-table td {
    padding: 8px;
    border: 1px solid #dddddd;
    text-align: left;
}

.checkbox-table th {
    background-color: #f2f2f2;
}

.checkbox-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.checkbox-table tbody tr:hover {
    background-color: #ddd;
}

.checkbox-table input[type="checkbox"] {
    cursor: pointer;
}

.reasonClass{
    font-family: 'Open Sans';
    font-size: 13px;
    width: 12rem;
    border: 1px solid #1e1d1d;
    border-radius: 3px;
    outline: none;
    border-color: black;
    
}

.ReasonLabel{
    font-weight: 600 !important;
    color: black !important;
    font-size: 13px !important;
    background-color:lavenderblush !important
   
}