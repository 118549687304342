.linkStyle {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countStyle {
  margin-left: "5px";
}

.reconcilationBoldText {
  font-size: 18px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  /* color: rgb(91, 39, 236); */
  opacity: 1;
}

.container {
  width: 430px;
  height: 341px;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  margin: 15px 0 0 15px;
}
.head-style {
  background: #d8dadd 0% 0% no-repeat padding-box;
  padding: 10px;
  margin: 0;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #36405d;
  opacity: 1;
  font-family: 'Avenir Next', sans-serif;
  padding-right: 10px;
  cursor: pointer;
}
.head-style:hover {
  background: #455570 0% 0% no-repeat padding-box;
  color: white;
}

.column-style {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #36405d;
  text-transform: capitalize;
  opacity: 1;
  font-family: 'Avenir Next', sans-serif;
  cursor: pointer;
}
.container {
  width: 350px !important;
  height: 290px;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  margin: 15px 0 0 15px;
  padding: 0px !important;
}
.exposure-head {
  margin-left: 10px;
  margin-top: 10px;
  padding: 0px;
}
.filter-section {
  margin-top: 15px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  height: 700px;
}
.filter-head {
  background: #D8DADD;
  padding: 10px;
  margin: 0;
  text-align: left;
  letter-spacing: 0px;
  color: #36405d;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  font-family: 'Avenir Next', sans-serif;

  padding-right: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.11);
}
.dropdown-head {
  text-align: left;
  font: normal normal normal 12px/20px Open Sans;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 0.8;
  margin-top : 25px;
}
.apply-button {
  text-align: center;
  margin-top : 10%;
}

.full-height-vertically {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.custom-space {
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px;
}
.breadcrumb{
  margin-top: 10px;
  margin-left: 10px
}
.dashboard-hover a {
  color: black; 
  text-decoration: none; 
}

.dashboard-hover a:hover {
  text-decoration: underline; 
}

.breadcrumb-container {
  background-color: #f0f0f0; 
  padding: 3px; 
  margin-bottom : 8px;
}
.area-names{
  margin-top: 15px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  height: auto;
  margin-left : 20px;
  margin-bottom : 30px;
}

.area-detail{
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  color: #36405D;
  text-transform: capitalize;
  opacity: 1;
  padding : 15px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.11);
  cursor: pointer;
}

.selAreaDetails {
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  padding : 15px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.11);
  color: #36405D;

  background: rgba(216, 218, 221, 0.5) no-repeat padding-box;
}
.table-footer{
 margin-bottom: 100px;
 margin-left: 20px;
}

.view-exposure-details{
  border: none;
}

.view-exposure-head{
  background: #F7F7F7 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
}
.view-head-with-margin{
  margin-left: 10px;
}

.key-values-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 0px 15px;
}

.key-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  height: 100px;
}

.key {
  font-weight: bold;
  font-size: small;
  margin-bottom: auto;
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  color: #36405D;
  text-transform: capitalize;
  opacity: 1;
}

.value {
  color: #555;
  font-size: small;
  margin-top: auto;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  color: #36405D;
  text-transform: capitalize;
  opacity: 1;
}
.container-lab{
  display: flex;
}
.box-display{
  width: 355px;
  height: 128px;
  margin: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  cursor: pointer;
}
.lab-dash-head{
  margin : 15px
}
.lab-dash-value{
  margin : 20px;
  text-align: left;
  font: normal normal bold 40px/55px Avenir Next;
  letter-spacing: 0px;
  opacity: 1;
}
.redText {
  color: red !important;
  cursor: pointer;
  font-weight: 600;
}
.dashTopTab {
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 15px;
  background: #f1f5fa 0% 0% no-repeat padding-box;
}

.dashTopTabDate input {
  /* width: 50%; */
  width: 195px;
  height: 35px;
}
.applyBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashTopTabDate label {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
  letter-spacing: 0px;
  color: #36405D;
  letter-spacing: 0px;
  opacity: 1;
}
.yellowText {
  color: rgb(91, 39, 236);
  cursor: pointer;
  filter: brightness(120%);
  text-shadow: 0 0 5px rgba(47, 44, 54, 0.2);
  font-weight: 600;
}

.yellowText {
  color: rgb(91, 39, 236);
  cursor: pointer;
  filter: brightness(120%);
  text-shadow: 0 0 5px rgba(47, 44, 54, 0.2);
  font-weight: 600;
}

@keyframes blink {
  50% {
    visibility: hidden;
  }
}

.highlight {
  /* Set initial color */
  color: var(--color1);
  transition: color 0.5s ease; /* Optional: Add a smooth transition effect */
  font-weight: 600;
  /* Blinking effect */
  &.blink {
    animation: blink 1s step-end infinite;
  }

  /* Define your colors */
  &.color1 {
    --color: #ff0000; /* Red */
  }

  &.color2 {
    --color:  #00ff00; /* Green */
  }

  /* Apply the color */
  color: var(--color);
}

#flow-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  width : 98%;
}

.flow-item {
  flex: 0 0 auto;
  text-align: center;

  position: relative;
}

.arrow {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.arrow span {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.flow-content {
  padding: 5px;
  /* margin-top: 20%; */
  border-radius: 5px;
  /* margin-bottom: 20%; */
}

.arrow-symbol {
  font-size: 98px;
  color: #e50f0f;
  margin-top: -51px;
  margin-bottom: -26px;
}

.custom-arrow-text {
  text-align: center;
  white-space: nowrap; 
  font-size: medium;
  color: #3fa704;
  font-weight: 500;
  margin-top: 2px;
}

.arrow-number {
  font-size: 22px;
  font-weight: bold;
}

.custom-arrow {
  font-size: 24px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flow-item {
  margin-bottom: 20px;
}

.square-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  max-width: 50%;
  margin: 0 auto;
  margin-top : 5%;
}

@media (min-width: 600px) {
  .square-container {
    flex-direction: row; /* Display in a row when screen width is 600px or more */
  }
}

.square-box:hover {
  background-color: #d8714f; /* Change the color on hover */
}

.warning-symbol,
.graph-symbol {
  font-size: 50px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

/* .square-box {
  flex: 0 0 calc(50% - 20px); 
  height: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  background-color: #FF7344; 
  text-decoration: none;
  color: black;
  font-size: 18px;
  border: 1px solid #ccc;
  transition: background-color 0.3s; 
  color: white;
  font-weight: 600;
} */
.pareto-head-chart{
  margin: "20px"
}
.pareto-head{
  font-size : "80px";
  font-weight: 600;
  padding : 40px 40px 20px 40px
}

.graphWrap {
  background-color: #ffffff;
  padding: 2%;
  margin: 2%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.gear-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #333; /* Adjust the color as needed */
}

.square-box {
  position: relative; /* Make the position relative for absolute positioning of the gear icon */
  flex: 0 0 calc(50% - 20px);
  height: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FF7344;
  text-decoration: none;
  color: black;
  font-size: 18px;
  border: 1px solid #ccc;
  transition: background-color 0.3s;
  color: white;
  font-weight: 600;
}

@media (min-width: 1400px){
  .container{
    max-width: 340px !important;
  }
};

#topDiv {
  color: red; /* Example style */
  font-size: 20px; /* Example style */
}
