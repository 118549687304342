

.scanWrapLg {
    background: #FDF1ED 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 2% 4%;
    display: flex;
    justify-content: start;
}
.scanWrapLg input {
    outline: none;
    border: none;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-weight: 600; 
    letter-spacing: 0px;
    color: #06152B;
    /* opacity: 0.2; */
    width: 85%;
}
.scanWrapLg input::placeholder {
    opacity: 1;
    font-weight: normal;
}

.scanInputWrapLg {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    border: 1px solid rgba(69, 84, 112, 0.4);
    border-radius: 4px;
    /* opacity: 0.4; */
    width: 55%;
}

.scannedDetailsWrap {
    border-left: 1px solid rgba(112, 112, 112, 0.2);
    /* margin-left: 20%; */
}

.scannedDetailsWrap .keyText {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal; 
    letter-spacing: 0px;
    color: #06152B;
    opacity: 1;
}
.scannedDetailsWrap .valueText {
    font-size: 15px;
    font-weight: 600; 
}

.exceptionBtn {
    text-decoration: underline;
    opacity: 1;
    outline: none;
    letter-spacing: 0px;
    color: #F0050E;
    border: none;
    background: none;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 600;
    cursor: pointer;
  }
.scanAddText {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-weight: 600; 
    letter-spacing: 0px;
    color: #FD7246;
    opacity: 1;
}
.scanWrapLg-flora {
    background: #FDF1ED 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0% 2%;
    /* display: flex; */
    justify-content: start;
}

.plate-detail-flora{
    text-align: left;
    font-family: "Open Sans";
    letter-spacing: 0px;
    color: #010307;
    opacity: 1;
    font-size: 14px;
    padding-top: 10px;
    white-space: nowrap;
    font-weight: normal;
  }