.labReportHead {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 10px #0000000F; */
  opacity: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.reportTab {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  opacity: 1;
  top: 70px;
  height: 58px;
  min-width: 100%;
  margin-left: 0%;
}

.selectedNavBtn {
  text-align: left;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ff7344;
  opacity: 1;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 3px solid #ff7344 !important;
  margin-top: 35px;
  background: none;
  margin: 2% 2%;
  padding-bottom: 10px;
}

.navBtn {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #05152b;
  opacity: 1;
  border: none;
  background: none;
  margin: 0 1%;
}
.approveLabReportBtn {
  background: #489308 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  opacity: 1;
  font-size: 13px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 0px 20px;
  border-radius: 5%;
  height: 35px;
  /* width: 97px; */
}

.rejectLabReportBtn {
  background: #ff1212 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  opacity: 1;
  font-size: 13px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 0px 20px;
  border-radius: 5%;
  height: 35px;
  /* width: 97px; */
}

.blueTableBtn {
  /* background: #0077b6 0% 0% no-repeat padding-box; */
  background: #ff7344 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  opacity: 1;
  font-size: 13px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 0px 20px;
  border-radius: 5%;
  height: 35px;
  /* width: 97px; */
}

.reportTabSelect {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ecebed;
  border-radius: 4px;
  opacity: 1;
  outline: none;
  padding: 0px 15px;
  letter-spacing: 0px;
  color: rgba(6, 21, 43, 0.3);
  width: 25vh;
  margin-right: 10px;
}

.clearAllBtn {
  text-decoration: underline;
  opacity: 1;
  outline: none;
  letter-spacing: 0px;
  color: #ff7344;
  /* width: 25vh; */
  border: none;
  background: none;
  text-align: left;
  margin-left: 10px;
  font-size: 13px;
  font-family: "Open Sans";
  font-weight: 600;
}

/* input.headerCheckBox {
  height: 12px;
  margin-left: 6px;
}

input.headerCheckBox {
  background-color: #ff7344;
  color: rgb(201, 53, 53);
}  */

.custom-checkbox {
  position: relative;
  display: inline-block;
  padding-left: 15px; /* Adjust as needed */
  cursor: pointer;
  height: 12px;
  margin-left: 6px;
}

.custom-checkbox input {
  display: none;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white; /* White when unchecked */
  border: 1px solid #ccc; /* Add a border for better visibility */
}

.custom-checkbox input:checked + .checkbox-checkmark {
  background-color: #ff7344; /* Orange when checked */
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  left: 3.5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0; /* Hide the checkmark by default */
}

.custom-checkbox input:checked + .checkbox-checkmark:after {
  opacity: 1; /* Show the checkmark when checked */
}


/* Modal.css */

.modal-overlay {
  width: 400px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  transition: 1.1s ease-out;
  transform: scale(1);
  visibility: visible;
  filter: blur(0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  text-align: center;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
}
.alert-message {
  font-size: 14px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: "semibold";
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.alert-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-right: 20px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.alert-head {
  background: #455470 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: left;
  font: normal normal normal 16px/18px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 400px;
  height: 54px;
  padding-top: 15px;
  padding-left: 15px;
}
.alert-btn2 {
  background: #ff7344 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: "semibold";
  letter-spacing: 0px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 0px 20px;
  border-radius: 5%;
  height: 40px;
  width: 97px;
  margin-left: 10px;
}
.alert-btn1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-family: "Open Sans";
  font-weight: "semibold";
  letter-spacing: 0px;
  color: #fe7344;
  outline: none;
  border: 1px solid #fe7346;
  padding: 0px 20px;
  border-radius: 5%;
  height: 40px;
  width: 97px;
  margin-left: 10px;
}
.view-modal {
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 0px !important;
}
.view-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: left;
  font-family: "Open Sans";
  font: normal normal normal 26px/36px;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
}
.view-body {
  border-top: 3px solid #ff7344 !important;
  padding: 0px !important;
}
.view-body-content {
  background: #fdf1ed 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 20px;
}
.plate-detail-key {
  text-align: left;
  font-family: "Open Sans";
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
  font-size: 12px;
  padding-top: 10px;
  white-space: nowrap;
  font-weight: normal;
}
.plate-detail-value {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
  font-weight: 600;
  font-family: "Open Sans";
  margin-top: -10%;
}
.modal-lg {
  width: 90% !important;
  --bs-modal-width: 1050px !important;
}
.modal-lg .modal-dialog {
  margin: 0 auto;
}
.view-lab-details-content {
  padding-top: 15px;
  text-align: left;
  padding-left: 20px;
  text-align: left;
  font: normal normal 600 16px/30px Open Sans;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
}
.detail-col-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  opacity: 1;
  padding-left: 10px;
  border-radius: 0px !important;
}
.detail-col-3 {
  width: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  padding-left: 5px;
}
.view-image-count-wrap {
  display: flex;
  align-items: center;
}
.view-image-count {
  width: 488px;
  height: 204px;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #cccccc;
  margin: 2% 0%;
  /* margin-bottom: 49px; */
}
.custom-modal-footer {
  display: flex;
  justify-content: space-between !important;
  align-items: end;
}
.footerApproveBtn {
  background: #489308 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 7px 64px #00000012 !important;
  opacity: 1 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  font-family: "Open Sans" !important;
  font-weight: "semibold" !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  outline: none !important;
  border: none !important;
  padding: 0px 20px !important;
  border-radius: 5% !important;
  height: 40px !important;
  width: 97px !important;
  margin-right: 10px;
}
.footerRejectBtn {
  background: #ff1212 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 7px 64px #00000012 !important;
  opacity: 1 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  font-family: "Open Sans" !important;
  font-weight: "semibold" !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  outline: none !important;
  border: none !important;
  padding: 0px 20px !important;
  border-radius: 5% !important;
  height: 40px !important;
  width: 97px !important;
  margin-right: 10px;
}
.footerCancelBtn {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 7px 64px #00000012 !important;
  border: 1px solid #474748 !important;
  opacity: 1 !important;
  padding: 0px 20px !important;
  border-radius: 5% !important;
  height: 40px !important;
  width: 97px !important;
}
.custom-modal-footer {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #0000002b !important;
  opacity: 1;
  border-top: 0px !important;
  margin-top : 20px;
}

.rejectModalTextarea {
  width: 85%;
  resize: none;
  outline: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans";
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 1;
}

.showLeftBtn {
  background: #ffff 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid red;
  color: red;
  opacity: 1;
  outline: none;
  border-radius: 10px;
}
.showLeftBtn:hover {
  background: red; /* Change the background color on hover */
  color: #151615; /* Change the text color on hover */
}

.dishowLeftBtn {
  background: #ffff 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid gray;
  color: gray;
  opacity: 1;
  outline: none;
  border-radius: 10px;
}

.showRightBtn {
  background: #ffff 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid green;
  color: green;
  opacity: 1;
  outline: none;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.showRightBtn:hover {
  background: #42ef0d; /* Change the background color on hover */
  color: #151615; /* Change the text color on hover */
}
.plate-detail-flora-value {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #06152b;
  opacity: 1;
  font-weight: 600;
  font-family: "Open Sans";
  margin-top: -4%;
}
.plate-detail-flora-modal{
  color: red;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
  font-family: "Open Sans";
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  padding-top: 10px;
  white-space: nowrap;
  font-weight: normal;
 
}